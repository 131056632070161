<template>
  <div class="standard-page">
    <!-- Show the button to go back -->
    <v-btn
      text
      class="mb-4"
      color="primary"
      @click="$router.go(-1)"
    >
      <v-icon left>
        arrow_back
      </v-icon>

      Back
    </v-btn>

    <!-- Show the stepwise form -->
    <v-stepper
      v-model="currentStep"
      vertical
    >
      <!-- The stepper header for General -->
      <v-stepper-step
        :complete="currentStep > 1"
        step="1"
      >
        General
        <small v-if="currentStep > 1">
          {{ formData.name }}
        </small>
      </v-stepper-step>

      <!-- The stepper content for General -->
      <v-stepper-content step="1">
        <v-card flat>
          <v-card-text class="mb-4">
            <v-row>
              <v-col cols="12" md="6">
                <!-- Show the input here -->
                <v-text-field
                  v-model="formData.name"
                  :hide-details="!$v.formData.name.$anyError"
                  :error-messages="$v.formData.name.$anyError ? ['Please enter a valid campaign name'] : null"
                  @blur="$v.formData.name.$touch"
                  label="Campaign Name"
                  outlined
                />
              </v-col>

              <v-col cols="12" md="6">
                <!-- Show the input for hashtags -->
                <v-combobox
                  v-model="formData.hashtags"
                  label="Hashtags"
                  :allow-overflow="true"
                  placeholder="Press enter to add more"
                  multiple
                  outlined
                  small-chips
                  deletable-chips
                  hint="Mention tags related to your campaign"
                  :error-messages="$v.formData.hashtags.$anyError ? ['Please enter valid hashtags'] : null"
                  @input="$v.formData.hashtags.$touch"
                ></v-combobox>
              </v-col>
            </v-row>

            <!-- Show the platforms selector -->
            <div class="mt-2  ">
              <div class="mb-2">
                Social Networks
              </div>
              <div class="d-flex items-center">
                <v-img
                  v-for="platform in Object.keys(formData.platforms)"
                  :key="platform"
                  :class="{ 'opaque': !formData.platforms[platform] }"
                  @click="() => (formData.platforms[platform] = !formData.platforms[platform])"
                  :src="`/img/socials/${platform}.svg`"
                  class="mr-3 pointer"
                  max-width="40"
                  height="40"
                ></v-img>
              </div>
            </div>
          </v-card-text>

          <v-card-actions class="pl-4">
            <v-btn
              depressed
              color="primary"
              @click="validateGeneral"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <!-- The stepper header for Budget -->
      <v-stepper-step
        :complete="currentStep > 2"
        step="2"
      >
        Budget
      </v-stepper-step>

      <!-- The stepper content for Budget -->
      <v-stepper-content step="2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <!-- Input: Budget value -->
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="formData.budgeting.budget"
                  :hide-details="!$v.formData.budgeting.budget.$anyError"
                  :error-messages="$v.formData.budgeting.budget.$anyError ? ['Please enter a valid amount'] : null"
                  @blur="$v.formData.budgeting.budget.$touch"
                  label="Budget"
                  outlined
                />
              </v-col>

              <!-- Input: Start date -->
              <v-col cols="12" md="6" lg="4">
                <v-menu
                  v-model="dialog.shouldShowStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="readableStartDate"
                      :hide-details="!$v.formData.budgeting.startDate.$anyError"
                      :error-messages="$v.formData.budgeting.startDate.$anyError ? ['Please select a date'] : null"
                      @blur="$v.formData.budgeting.startDate.$touch"
                      label="Start Date"
                      prepend-inner-icon="today"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    show-current
                    show-adjacent-months
                    :min="dayjs().subtract(89, 'day').format('YYYY-MM-DD')"
                    v-model="formData.budgeting.startDate"
                    @input="dialog.shouldShowStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Input: End date -->
              <v-col cols="12" md="6" lg="4">
                <v-menu
                  v-model="dialog.shouldShowEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="readableEndDate"
                      :hide-details="!$v.formData.budgeting.endDate.$anyError"
                      :error-messages="$v.formData.budgeting.endDate.$anyError ? ['Please select a valid date'] : null"
                      @blur="$v.formData.budgeting.endDate.$touch"
                      label="End Date"
                      prepend-inner-icon="event"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    show-current
                    show-adjacent-months
                    v-model="formData.budgeting.endDate"
                    @input="dialog.shouldShowEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Textarea: Description -->
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="formData.budgeting.description"
                  :hide-details="!$v.formData.budgeting.description.$anyError"
                  :error-messages="$v.formData.budgeting.description.$anyError ? ['Please enter the campaign description under 2,000 characters'] : null"
                  @blur="$v.formData.budgeting.description.$touch"
                  label="Description"
                  outlined
                ></v-textarea>
              </v-col>

              <!-- Textarea: Goal -->
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="formData.budgeting.goal"
                  :hide-details="!$v.formData.budgeting.goal.$anyError"
                  :error-messages="$v.formData.budgeting.goal.$anyError ? ['Please enter the campaign goal under 2,000 characters'] : null"
                  @blur="$v.formData.budgeting.goal.$touch"
                  label="Goal"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="pl-4">
            <v-btn
              depressed
              color="primary"
              @click="validateBudget"
            >
              Continue
            </v-btn>
            <v-btn
              text
              @click="currentStep = 1"
            >
              Back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="currentStep > 3"
        step="3"
      >
        Target
      </v-stepper-step>

      <!-- The stepper content for Target -->
      <v-stepper-content step="3">
        <v-card flat>
          <v-card-text>
            <v-row>
              <!-- Input: Location -->
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="formData.targeting.location"
                  :hide-details="!$v.formData.targeting.location.$anyError"
                  :error-messages="$v.formData.targeting.location.$anyError ? ['Please enter your target location'] : null"
                  @blur="$v.formData.targeting.location.$touch"
                  label="Location"
                  outlined
                />
              </v-col>

              <!-- Input: Gender -->
              <v-col cols="12" md="6" lg="4">
                <v-select
                  v-model="formData.targeting.gender"
                  :hide-details="!$v.formData.targeting.gender.$anyError"
                  :error-messages="$v.formData.targeting.gender.$anyError ? ['Please select your target gender'] : null"
                  @blur="$v.formData.targeting.gender.$touch"
                  :items="genderOptions"
                  label="Gender"
                  outlined
                ></v-select>
              </v-col>

              <!-- Input: Age Range -->
              <v-col cols="12" md="6" lg="4">
                <div class="d-flex justify-space-between">
                  <age-selector
                    v-model="formData.targeting.age.from"
                    label="Age (From)"
                    :max-value="formData.targeting.age.to"
                    :dense="false"
                    class="mr-1"
                  />

                  <age-selector
                    v-model="formData.targeting.age.to"
                    label="Age (To)"
                    :min-value="formData.targeting.age.from"
                    :dense="false"
                    class="mr-1"
                  />

                  <weight-selector
                    v-model="formData.targeting.age.weight"
                    label="Weightage"
                    :dense="false"
                  />
                </div>
              </v-col>

              <!-- Combobox: Interests -->
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="formData.targeting.interests"
                  label="Interests"
                  :allow-overflow="true"
                  placeholder="Press enter to add more"
                  multiple
                  outlined
                  small-chips
                  deletable-chips
                  hint="Mention interests related to your targeted audience"
                  :error-messages="$v.formData.targeting.interests.$anyError ? ['Please enter your interests'] : null"
                  @input="$v.formData.targeting.interests.$touch"
                ></v-combobox>
              </v-col>

              <!-- Textarea: Goal -->
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="formData.targeting.text"
                  :hide-details="!$v.formData.targeting.text.$anyError"
                  :error-messages="$v.formData.targeting.text.$anyError ? ['Please describe your target audience'] : null"
                  @blur="$v.formData.targeting.text.$touch"
                  label="More About Target Audience"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="pl-4">
            <v-btn
              depressed
              color="primary"
              @click="validateTarget"
              :disabled="isLoading"
              :loading="isLoading"
            >
              Continue
            </v-btn>
            <v-btn
              text
              @click="currentStep = 2"
              :disabled="isLoading"
            >
              Back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength, minValue, maxValue, numeric, decimal } from "vuelidate/lib/validators"

// Import child components
const AgeSelector = () => import(/* webpackChunkName: "age-selector" */ "@/blocks/common/selectors/AgeSelector.vue")
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachForm",

  // Register children components
  components: {
    AgeSelector,
    WeightSelector
  },

  // Accept incoming data from parent
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    model: {
      type: Object,
      required: false,
      default: null
    }
  },

  // Define local data variables
  data: () => ({
    // The current stepper page to be shown
    currentStep: 1,

    // The visible menu dialogs
    dialog: {
      shouldShowStartDate: false,
      shouldShowEndDate: false
    },

    // The form input options
    genderOptions: [
      {
        text: "All",
        value: "all"
      },
      {
        text: "Male",
        value: "male",
      },
      {
        text: "Female",
        value: "female"
      },
      {
        text: "Others",
        value: "others"
      }
    ],

    // The form data to be used
    formData: {
      name: "",
      hashtags: [],
      platforms: {
        instagram: true,
        youtube: false,
        tiktok: false
      },

      budgeting: {
        budget: "",
        startDate: "",
        endDate: "",
        description: "",
        goal: ""
      },

      targeting: {
        location: "",
        gender: "",
        age: {
          from: 13,
          to: 100,
          weight: 0.05
        },
        interests: [],
        text: ""
      }
    },
  }),

  // Define computable readonly variables
  computed: {
    /**
     * Get the formatted value from startDate
     *
     * @returns {String|Null}
     */
    readableStartDate() {
      return this.formData.budgeting.startDate ? dayjs(this.formData.budgeting.startDate).format("ll") : null
    },

    /**
     * Get the formatted value from endDate
     *
     * @returns {String|Null}
     */
     readableEndDate() {
      return this.formData.budgeting.endDate ? dayjs(this.formData.budgeting.endDate).format("ll") : null
    }
  },

  // Define validations for this form
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200)
      },
      hashtags: {},
      budgeting: {
        budget: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(1_000_000_000)
        },
        startDate: {
          required,
          minimum: (v) => dayjs().diff(dayjs(v), 'day') <= 90
        },
        endDate: {
          required
        },
        description: {
          required,
          maxLength: maxLength(2000)
        },
        goal: {
          required,
          maxLength: maxLength(2000)
        }
      },
      targeting: {
        location: {
          required,
          maxLength: maxLength(200)
        },
        gender: {
          required
        },
        age: {
          from: {
            required,
            numeric,
            minValue: minValue(1),
            maxValue: maxValue(150)
          },
          to: {
            required,
            numeric,
            minValue: minValue(1),
            maxValue: maxValue(150)
          },
          weight: {
            required,
            decimal,
            minValue: minValue(0),
            maxValue: maxValue(1)
          }
        },
        interests: {},
        text: {
          required
        }
      }
    }
  },

  // Define method functions
  methods: {
    /**
     * Run the validations and if correct, take it to next step
     *
     * @returns {void}
     */
    async validateGeneral() {
      // Check if the input is valid
      await this.$v.formData.name.$touch()

      // If it is invalid
      if (this.$v.formData.name.$anyError) {
        return
      }

      // Otherwise
      this.currentStep = 2
    },

    /**
     * Run the validations and if correct, take it to the next step
     *
     * @returns {void}
     */
    async validateBudget() {
      // Check if the inputs are valid
      await this.$v.formData.budgeting.$touch()

      // If it is invalid
      if (this.$v.formData.budgeting.$anyError) {
        return
      }

      // Otherwise
      this.currentStep = 3
    },

    /**
     * Run the validations and if correct, save the campaign and redirect
     *
     * @returns {void}
     */
    async validateTarget() {
      // Check if the inputs are valid
      await this.$v.formData.targeting.$touch()

      // If it is invalid
      if (this.$v.formData.targeting.$anyError) {
        return
      }

      // Otherwise, emit the event
      this.$emit("submit", this.formData)
    }
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // If we have a model data already
    if (this.model) {
      // Update the formData with these values
      this.formData = {
        name: this.model.name,
        hashtags: this.model.hashtags,
        platforms: {
          instagram: this.model.platforms.includes("instagram"),
          youtube: this.model.platforms.includes("youtube"),
          tiktok: this.model.platforms.includes("tiktok")
        },

        budgeting: {
          budget: this.model.budget,
          startDate: this.model.start_date,
          endDate: this.model.end_date,
          description: this.model.description,
          goal: this.model.goal
        },

        targeting: {
          location: this.model.target_location,
          gender: this.model.target_gender,
          age: {
            from: this.model.target_age_from,
            to: this.model.target_age_to,
            weight: this.model.target_age_weightage
          },
          interests: this.model.target_interests,
          text: this.model.target_text
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.opaque
  opacity 0.2

.contain-select-width
  min-width unset !important
  width unset !important
</style>
